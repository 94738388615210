<template>
  <Modal
    v-model="addSimilarProduct"
    @ok="SEND_PRODUCT_TO_SIMILAR_PRODUCT"
    ref="addSimilarProductModal"
    :title="$t('addSimilarProduct')"
  >
    <section class="vid-title-sec">
      <div class="container">
        <div
          class="alert"
          :class="`alert-${alertBox.variant}`"
          v-if="alertBox.status"
        >
          <ul>
            <li v-for="(message, i) in alertBox.message" :key="i">
              {{ message }}
            </li>
          </ul>
        </div>
        <div v-if="!showMessage">
          <form @submit.prevent="CONTROL">
            <div class="vid-title">
              <h2 class="title-hd">{{ $t("productName") }}</h2>
              <div class="form_field">
                <input
                  type="text"
                  maxlength="100"
                  v-model="search"
                  @input="LISTEN_KEY_OF_SEARCH"
                  :placeholder="$t('searchProductName')"
                />
              </div>
            </div>
          </form>
          <div v-if="searched">
            <page-loading v-if="productLoading"></page-loading>
            <div class="my-3 text-center" v-else-if="products.length === 0">
              <p>{{ $t("noFoundData") }}</p>
            </div>
            <div class="mt-4" v-else>
              <h5>{{ $t("products") }}</h5>
              <p>{{ $t("similarProductSelectDesc") }}</p>
              <div class="text-right">
                <small>
                  {{ $t("choosedItem", { count: selected.length }) }}
                </small>
              </div>
              <div
                class="product-list-similar"
                :class="{
                  'product-list-similar-active': selected.find(
                    (s) => s.id === product.id
                  ),
                  'product-list-similar-has-it': product.isSimilar,
                }"
                @click="SELECT_ITEM(product)"
                v-for="(product, i) in products"
                :key="i"
              >
                {{ i + 1 }}. {{ product.title }}
                <img
                  :src="`/images/flags/${
                    product.langCode?.toLowerCase() || 'tr'
                  }.svg`"
                  width="30"
                  alt=""
                />
                <span
                  v-if="product.isSimilar"
                  class="mx-2 badge badge-success"
                  >{{ $t("alreadyHasIt") }}</span
                >
                <div>
                  <small>
                    <img
                      :src="FIND_TYPE_ALT(product.type).icon"
                      width="20"
                      class="rounded-0"
                      alt=""
                    />
                    {{ FIND_TYPE_ALT(product.type).title }}</small
                  >
                  - {{ product.category.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section></Modal
  >
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
import PageLoading from "../Shared/PageLoading.vue";
import Types from "../Type/Types";
export default {
  components: { Modal, PageLoading },
  mixins: [Types],
  data() {
    return {
      addSimilarProduct: false,
      searched: false,
      showMessage: false,
      timeout: -1,
      search: "",
      products: [],
      productLoading: false,
      selected: [],
      form: { id: -1, name: "" },
      alertBox: { status: false, message: "", variant: "success" },
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
      },
    },
    addSimilarProduct(data) {
      if (!data) {
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
        this.CLEAR_ALL();
      }
    },
  },
  props: {
    similarProducts: {
      default: () => [],
    },
    product: {
      default: () => {},
    },
  },
  methods: {
    CONTROL: () => false,
    CLEAR_ALL() {
      this.searched = false;
      this.selected = [];
      this.search = "";
      this.products = [];
    },
    SELECT_ITEM(product) {
      if (product.isSimilar) return;
      const p = this.selected.findIndex((s) => s.id === product.id);
      if (p === -1) this.selected.push(product);
      else this.selected.splice(p, 1);
    },
    LISTEN_KEY_OF_SEARCH() {
      if (this.timeout !== -1) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.SEARCH_PRODUCT();
      }, 1000);
    },
    async SEARCH_PRODUCT() {
      this.selected = [];
      this.productLoading = true;
      this.searched = true;
      const response = await this.$api.get(
        `Products?searchKey=${this.search}&currency=TRY`
      );
      if (response.message === "OK")
        this.products = response.data.items.map((item) => ({
          ...item,
          isSimilar:
            this.similarProducts.findIndex((sp) => sp.productId === item.id) !==
            -1,
        }));
      else this.products = [];
      this.productLoading = false;
    },
    async SEND_PRODUCT_TO_SIMILAR_PRODUCT(loading) {
      loading(true);
      this.showMessage = true;
      const response = await Promise.all(
        this.selected.map((s) =>
          this.$api.post("Products/SimilarProduct", {
            productId: this.product.id,
            similarProductId: s.id,
          })
        )
      );
      const alertBoxMessages = [];
      response.forEach((res, i) => {
        if (res.message === "OK") {
          alertBoxMessages.push(
            this.$t("addedItem", {
              product:
                this.selected[i].title +
                " - " +
                this.FIND_TYPE_ALT(this.selected[i].type).title,
            })
          );
        } else {
          alertBoxMessages.push(
            this.$t("notAddedItem", {
              product:
                this.selected[i].title +
                " - " +
                this.FIND_TYPE_ALT(this.selected[i].type).title,
            })
          );
        }
      });
      this.alertBox = {
        status: true,
        variant: "info",
        message: alertBoxMessages,
      };
      this.CLEAR_ALL();
      setTimeout(() => {
        this.showMessage = false;
      }, 3000);
      this.$emit("refresh");
      loading(false);
    },
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal === "add-similar-product") {
          this.addSimilarProduct = true;
        } else this.addSimilarProduct = false;
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
  },
};
</script>

<style>
.product-list-similar {
  padding: 10px;
  border-bottom: 1px solid #dedede;
}
.product-list-similar:hover {
  background: #dedede;
  cursor: pointer;
}
.product-list-similar-active {
  background: #ff5740 !important;
  color: white !important;
}
.product-list-similar-has-it {
  opacity: 0.2;
}
</style>
