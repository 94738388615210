<template>
  <div class="container mt-3">
    <page-loading v-if="loading"></page-loading>
    <NoFound
      :buttonText="$t('addSimilarProduct')"
      :link="link"
      v-else-if="similarProducts.length === 0"
    ></NoFound>
    <div v-else>
      <h2>
        {{ $t("similarProducts") }}
        <span class="text-muted">{{ similarProducts.length }}</span>
      </h2>
      <h5 class="text-muted mt-2 mb-4">
        {{ $t("similarProductsDescription") }}
      </h5>

      <div class="text-right">
        <router-link :to="link">
          <button class="btn btn-pill donate">
            {{ $t("addSimilarProduct") }}
          </button>
        </router-link>
      </div>
      <div class="mt-3">
        <Table
          :headers="headers"
          :items="similarProducts"
          no-search
          :loading="loading"
          :emptyText="$t('noFoundData')"
        >
          <template #title="{ item }">
            {{ item.title }}
            <small
              ><img
                :src="FIND_TYPE_ALT(item.type).icon"
                width="20"
                class="rounded-0"
                alt=""
              />
              {{ FIND_TYPE_ALT(item.type).title }}</small
            >
          </template>
          <template #status="{ item }">
            <button
              @click="REMOVE_ITEM(item)"
              class="btn btn-sm btn-pill btn-danger"
            >
              {{ $t("remove") }}
            </button>
          </template>
        </Table>
      </div>
    </div>
    <AddSimilarProduct
      :similarProducts="similarProducts"
      @refresh="GET_SIMILAR_PRODUCTS"
      :product="product"
    ></AddSimilarProduct>
    <Modal
      v-model="showRemoveItemConfirm"
      :okText="$t('yes')"
      sm
      @ok="REMOVE_SIMILAR_PRODUCT"
    >
      {{ $t("similarProductRemoveDescription") }}</Modal
    >
  </div>
</template>

<script>
import NoFound from "../Shared/NoFound.vue";
import PageLoading from "../Shared/PageLoading.vue";
import Table from "../Tables/index.vue";
import AddSimilarProduct from "./AddSimilarProduct.vue";
import Modal from "../Shared/Modal/index.vue";
import AlertBox from "../../mixins/AlertBox";
import Types from "../Type/Types";
export default {
  components: { PageLoading, NoFound, Table, AddSimilarProduct, Modal },
  mixins: [AlertBox, Types],
  props: {
    product: {
      default: () => {},
    },
  },
  data() {
    return {
      similarProducts: [],
      activeItem: {},
      loading: false,
      showRemoveItemConfirm: false,
      headers: [
        {
          col: 12,
          md: 9,
          lg: 9,
          title: this.$t("productName"),
          value: "title",
        },
        {
          col: 12,
          md: 3,
          lg: 3,
          title: this.$t("situation"),
          value: "status",
        },
      ],
    };
  },
  computed: {
    link() {
      return { query: { ...this.$route.query, modal: "add-similar-product" } };
    },
  },
  methods: {
    async REMOVE_SIMILAR_PRODUCT(loading) {
      loading(true);
      const response = await this.$api.delete(
        `Products/SimilarProduct/${this.activeItem.id}`
      );
      if (response.message === "OK") {
        this.SET_ALERT_BOX("success", this.$t("removeActionIsSucceed"));
      } else this.SET_ALERT_BOX("danger", this.$t("removeActionIsFailed"));
      this.GET_SIMILAR_PRODUCTS();
      loading(false);
      this.showRemoveItemConfirm = false;
    },
    REMOVE_ITEM(item) {
      this.activeItem = item;
      this.showRemoveItemConfirm = true;
    },
    async GET_SIMILAR_PRODUCTS() {
      this.loading = true;
      const response = await this.$api.get(
        `Products/SimilarProduct?productId=${this.product.id}`
      );
      if (response.message === "OK") {
        this.similarProducts = response.data;
      } else this.similarProducts = [];
      this.loading = false;
    },
  },
  mounted() {
    this.GET_SIMILAR_PRODUCTS();
  },
};
</script>

<style></style>
