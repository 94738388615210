var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{ref:"addSimilarProductModal",attrs:{"title":_vm.$t('addSimilarProduct')},on:{"ok":_vm.SEND_PRODUCT_TO_SIMILAR_PRODUCT},model:{value:(_vm.addSimilarProduct),callback:function ($$v) {_vm.addSimilarProduct=$$v},expression:"addSimilarProduct"}},[_c('section',{staticClass:"vid-title-sec"},[_c('div',{staticClass:"container"},[(_vm.alertBox.status)?_c('div',{staticClass:"alert",class:`alert-${_vm.alertBox.variant}`},[_c('ul',_vm._l((_vm.alertBox.message),function(message,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(message)+" ")])}),0)]):_vm._e(),(!_vm.showMessage)?_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.CONTROL.apply(null, arguments)}}},[_c('div',{staticClass:"vid-title"},[_c('h2',{staticClass:"title-hd"},[_vm._v(_vm._s(_vm.$t("productName")))]),_c('div',{staticClass:"form_field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"type":"text","maxlength":"100","placeholder":_vm.$t('searchProductName')},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing)return;_vm.search=$event.target.value},_vm.LISTEN_KEY_OF_SEARCH]}})])])]),(_vm.searched)?_c('div',[(_vm.productLoading)?_c('page-loading'):(_vm.products.length === 0)?_c('div',{staticClass:"my-3 text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t("noFoundData")))])]):_c('div',{staticClass:"mt-4"},[_c('h5',[_vm._v(_vm._s(_vm.$t("products")))]),_c('p',[_vm._v(_vm._s(_vm.$t("similarProductSelectDesc")))]),_c('div',{staticClass:"text-right"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t("choosedItem", { count: _vm.selected.length }))+" ")])]),_vm._l((_vm.products),function(product,i){return _c('div',{key:i,staticClass:"product-list-similar",class:{
                'product-list-similar-active': _vm.selected.find(
                  (s) => s.id === product.id
                ),
                'product-list-similar-has-it': product.isSimilar,
              },on:{"click":function($event){return _vm.SELECT_ITEM(product)}}},[_vm._v(" "+_vm._s(i + 1)+". "+_vm._s(product.title)+" "),_c('img',{attrs:{"src":`/images/flags/${
                  product.langCode?.toLowerCase() || 'tr'
                }.svg`,"width":"30","alt":""}}),(product.isSimilar)?_c('span',{staticClass:"mx-2 badge badge-success"},[_vm._v(_vm._s(_vm.$t("alreadyHasIt")))]):_vm._e(),_c('div',[_c('small',[_c('img',{staticClass:"rounded-0",attrs:{"src":_vm.FIND_TYPE_ALT(product.type).icon,"width":"20","alt":""}}),_vm._v(" "+_vm._s(_vm.FIND_TYPE_ALT(product.type).title))]),_vm._v(" - "+_vm._s(product.category.name)+" ")])])})],2)],1):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }